'use client';

import {
  AccordionLabel,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
  Accordion,
  Button,
} from '@app/ui-system';
import {
  Devices,
  HandCoins,
  MagnifyingGlass,
  PlusCircle,
  MoonStars,
  Sliders,
} from '@phosphor-icons/react';
import Link from 'next/link';
import { twMerge } from 'tailwind-merge';

import { useBrandAndModelsQuery } from '@/generated/graphql';

const Feature = ({
  imgSrc,
  header,
  text,
  position,
}: {
  imgSrc: string;
  header: string;
  text: string;
  position: 'left' | 'right';
}) => {
  const isLeft = position === 'left';

  const imgContent = (
    <div className={twMerge('col-span-12 sm:col-span-6')}>
      <div className="rounded-3xl bg-gradient-to-b from-zinc-300 to-transparent p-px dark:from-zinc-700">
        <div className="rounded-[calc(1.5rem-1px)] bg-zinc-100 p-10 dark:bg-zinc-950">
          <img
            loading="lazy"
            src={imgSrc}
            alt={header}
            className="w-full rounded-2xl shadow-lg"
            height={373}
          />
        </div>
      </div>
    </div>
  );

  const textContent = (
    <div
      className={twMerge(
        'col-span-12 flex items-center sm:col-span-6',
        isLeft && 'order-first sm:order-none',
      )}
    >
      <div>
        <h3 className="dark:text-white">{header}</h3>
        <p>{text}</p>
      </div>
    </div>
  );

  return (
    <div
      className={twMerge(
        'mx-auto grid max-w-4xl grid-cols-12 gap-4 py-12 md:gap-8 md:px-1',
      )}
    >
      {isLeft ? imgContent : textContent}
      {isLeft ? textContent : imgContent}
    </div>
  );
};

const Page = () => {
  const [brandsQuery] = useBrandAndModelsQuery();

  return (
    <section className="body-font text-gray-600">
      <div className="mx-auto max-w-5xl pb-24 pt-40">
        <h1 className="text-80 font-4 lh-6 ld-04 mb-6 text-center font-bold text-gray-800 dark:text-white md:text-6xl">
          Przetestuj nową platformę ogłoszeń motoryzacyjnych
        </h1>
        <h2 className="font-4 lh-6 ld-04 pb-11 text-center text-2xl font-normal text-gray-600 dark:text-gray-400">
          Darmowe ogłoszenia samochodowe. <br /> Zapoznaj się z naszą szeroką
          gamą samochodów i znajdź ten idealny dla siebie.
        </h2>

        <div className="flex flex-col justify-center gap-4 text-center md:flex-row">
          <Link href="/oferta/dodaj">
            <Button variant="secondary" size="lg">
              <PlusCircle weight="bold" className="mr-1 text-base" /> Dodaj
              ogłoszenie
            </Button>
          </Link>

          <Link href="/oferty">
            <Button variant="default" size="lg">
              <MagnifyingGlass weight="bold" className="mr-1 text-base" />{' '}
              Szukaj teraz!
            </Button>
          </Link>
        </div>
      </div>
      <div className="container mx-auto flex flex-col items-center justify-center p-0">
        <img
          src="/static/devices.png"
          alt="Devices"
          width={2026}
          height={1268}
        />
      </div>

      <h2 className="mb-1 pt-40 text-center text-2xl font-semibold tracking-tighter text-gray-800 dark:text-gray-200 md:text-6xl">
        Co oferujemy?
      </h2>
      <br />
      <p className="mx-auto text-center text-xl font-normal leading-relaxed text-gray-600 dark:text-gray-400 lg:w-2/3">
        Darmowa sprzedaż samochodów używanych, znajdziesz u nas samochody z
        komisu i nowe auta z salonu. Poszukiwania auta z zagranicy to dla nas
        nie problem, znajdziesz u nas samochody z USA.
      </p>

      <Feature
        position="left"
        imgSrc="/static/feature_1.png"
        header="1. Profil sprzedawcy"
        text="Każdy sprzedawca ma możliwość założenia własnego profilu, gdzie może umieścić swoje dane kontaktowe, logo firmy oraz baner reklamowy."
      />

      <Feature
        position="right"
        imgSrc="/static/feature_2.png"
        header="2. Tryb ciemny"
        text="Specjalny nocny tryb wyświetlania, który zmniejsza zmęczenie oczu. Możesz przełączać się między trybem jasnym i ciemnym w każdej chwili."
      />

      <Feature
        position="left"
        imgSrc="/static/feature_3.png"
        header="3. Ogromna liczba filtrów"
        text="Szeroki wybór filtrów pozwala na dokładne dopasowanie ofert do Twoich potrzeb. Możesz filtrować oferty według marki, modelu, rocznika, przebiegu i wielu innych."
      />

      <Feature
        position="right"
        imgSrc="/static/feature_4.png"
        header="4. Wszystko za darmo"
        text="Oferty i profil firmowy jest całkowicie darmowy. Nie ma żadnych ukrytych kosztów, a dodawanie ogłoszeń jest proste i intuicyjne."
      />

      <div className="mt-12 flex flex-col justify-center gap-4 text-center md:flex-row">
        <Link href="/oferta/dodaj">
          <Button variant="secondary" size="lg">
            <PlusCircle weight="bold" className="mr-1 text-base" /> Dodaj
            ogłoszenie
          </Button>
        </Link>

        <Link href="/oferty">
          <Button variant="default" size="lg">
            <MagnifyingGlass weight="bold" className="mr-1 text-base" /> Szukaj
            teraz!
          </Button>
        </Link>
      </div>

      <h2 className="mb-1 pt-40 text-center text-2xl font-semibold tracking-tighter text-gray-800 dark:text-gray-200 md:text-6xl">
        Czym jest nasz serwis z ogłoszeniami
      </h2>
      <br />

      <div className="mx-auto text-center text-xl font-normal leading-relaxed text-gray-800 dark:text-gray-200 lg:w-2/3">
        <p>
          Czy szukasz idealnego samochodu używanego? Czy chcesz sprzedać swój
          obecny pojazd? Nasz serwis oferuje Ci możliwość darmowego dodania
          ogłoszenia samochodowego, bez względu na to, czy jesteś kupującym, czy
          sprzedającym.
        </p>
      </div>

      <div className="mx-auto grid max-w-4xl grid-cols-12 gap-2 pb-24 pt-12 md:gap-8 md:px-1">
        <div className="col-span-12 mt-6 flex flex-col items-center text-center sm:col-span-6 lg:col-span-3">
          <Sliders size={64} color="#1c3eab" weight="duotone" />
          <h3 className="pt-3 text-lg font-semibold text-gray-800 dark:text-gray-200">
            Profil sprzedawcy <br /> 30+ filtrów wyszukiwania
          </h3>
        </div>
        <div className="col-span-12 mt-6 flex flex-col items-center text-center sm:col-span-6 lg:col-span-3">
          <HandCoins size={64} color="#1c3eab" weight="duotone" />
          <h3 className="pt-3 text-lg font-semibold text-gray-800 dark:text-gray-200">
            Darmowe ogłoszenia bez ukrytych kosztów
          </h3>
        </div>
        <div className="col-span-12 mt-6 flex flex-col items-center text-center sm:col-span-6 lg:col-span-3">
          <Devices size={64} color="#1c3eab" weight="duotone" />
          <h3 className="pt-3 text-lg font-semibold text-gray-800 dark:text-gray-200">
            Jedna aplikacja na telefony i komputery
          </h3>
        </div>
        <div className="col-span-12 mt-6 flex flex-col items-center text-center sm:col-span-6 lg:col-span-3">
          <MoonStars size={64} color="#1c3eab" weight="duotone" />
          <h3 className="pt-3 text-lg font-semibold text-gray-800 dark:text-gray-200">
            Wsparcie dark mode do wieczornego przeglądania
          </h3>
        </div>
      </div>

      <h2 className="mb-1 pt-40 text-center text-2xl font-semibold tracking-tighter text-gray-800 dark:text-gray-200 md:text-6xl">
        Często zadawane pytania
      </h2>
      <br />

      <div className="mx-auto grid max-w-4xl px-3 md:px-1">
        <Accordion type="single">
          <AccordionItem value="ad1">
            <AccordionTrigger>
              <AccordionLabel>
                Czy strona jest całkowicie darmowa?
              </AccordionLabel>
            </AccordionTrigger>
            <AccordionContent>
              Nie ma żadnych haczyków, dodawanie ogłoszeń jest całkowicie
              darmowe, nie ma żadnych ukrytych kosztów
            </AccordionContent>
          </AccordionItem>

          <AccordionItem value="ad3">
            <AccordionTrigger>
              <AccordionLabel>
                Gdzie mogę znaleźć aplikację Android/iOS?
              </AccordionLabel>
            </AccordionTrigger>
            <AccordionContent>
              Niestety nie planujemy tworzyć dedykowanej aplikacji mobilnej,
              jednak strona intenetowa jest responsywna i działa na telefonach
            </AccordionContent>
          </AccordionItem>

          <AccordionItem value="ad2">
            <AccordionTrigger>
              <AccordionLabel>
                Czy mogę założyć profil sprzedawcy?
              </AccordionLabel>
            </AccordionTrigger>
            <AccordionContent>
              Tak posiadamy możliwość tworzenia spersionaliowanej strony z
              banerem i logo firmy. Dodatkowo możemy filtrować oferty pod kątem
              jednego sprzedawcy.
            </AccordionContent>
          </AccordionItem>

          <AccordionItem value="ad5">
            <AccordionTrigger>
              <AccordionLabel>
                W jakim kierunku będzie rozwijać się strona?
              </AccordionLabel>
            </AccordionTrigger>
            <AccordionContent>
              Strona jest w fazie testów, chcemy słuchać głosu użytkowników i
              rozwijać się zgodnie z wizją większości
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>

      <h2 className="mb-1 pt-40 text-center text-2xl font-semibold tracking-tighter text-gray-800 dark:text-gray-200 md:text-6xl">
        Popularne marki samochodowe
      </h2>
      <br />
      <p className="fs521 mx-auto text-center text-xl font-normal leading-relaxed text-gray-600 dark:text-gray-400 lg:w-2/3">
        Przetestuj nową platformę dla aut używanych zupelnie za darmo
      </p>

      <div className="mx-auto grid max-w-6xl grid-cols-12 px-3 pb-32 pt-32 md:px-1 md:pl-[100px]">
        {brandsQuery.data?.brand.map((brand) => (
          <div
            key={brand.name}
            className="col-span-6 my-1 md:col-span-4 lg:col-span-3"
          >
            <Link className="text-gray-400" href={`/oferty/${brand.slug}`}>
              {brand.name}
            </Link>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Page;
